import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { ReactComponent as CameraIcon } from '@assets/icons/camera.svg'
import Box from '@lib/ui/core/Box'
import SvgIcon from '@lib/ui/core/SvgIcon'
import IconButton from '@lib/ui/core/IconButton'
import UserProfileImg from '@assets/images/profile.png'
import AvatarUpload from './AvatarUpload'

const AvatarPanel = () => {
  const avatar = useStoreState((state) => state.user.profile.avatar)
  const [uploadOpen, setUploadOpen] = useState(false)

  const handleUploadOpen = () => {
    setUploadOpen(true)
  }

  const handleUploadClose = () => {
    setUploadOpen(false)
  }

  return (
    <Box>
      <Box position="relative" height="7.2rem" bgcolor="background.gray" mb="2.3rem">
        <Box display="flex" justifyContent="center" pt="1.9rem">
          <Box position="relative">
            <Box width="6.2rem" height="6.2rem" display="block" borderRadius="50%" clone>
              <img src={avatar || UserProfileImg} alt="" />
            </Box>
            <Box position="absolute" right="0" bottom="0" bgcolor="#AAAAAA" borderRadius="50%">
              <Box width="1.5rem" height="1.5rem" clone>
                <IconButton style={{ padding: 0 }} onClick={handleUploadOpen}>
                  <SvgIcon component={CameraIcon} order={1} borderColor="#fff" color="#AAAAAA" fontSize="1rem" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <AvatarUpload open={uploadOpen} onClose={handleUploadClose} />
    </Box>
  )
}

export default AvatarPanel
