import React from 'react'
import Layout from '@layouts/MainLayout'
import SEO from '@components/SEO'
import ProfilePanel from '@components/ProfileEdit'
import Auth from '@components/Auth'

const ProfileEdit = () => (
  <Auth>
    <Layout>
      <SEO />
      <ProfilePanel />
    </Layout>
  </Auth>
)

export default ProfileEdit
