import React, { useState } from 'react'
import Button from '@lib/ui/core/Button'
import Box from '@lib/ui/core/Box'
import InputBase from '@lib/ui/core/InputBase'
import Modal from '@lib/ui/core/Modal'
import Typography from '@lib/ui/core/Typography'

const EditPopup = ({ open, title = '', onClose, onSubmit, children }) => {
  const [value, setValue] = useState('')

  const handleChange = (ev) => {
    setValue(ev.target.value)
  }

  const updateInfo = () => {
    onSubmit(value)
  }

  const handleClose = () => {
    setValue('')
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box mb={2.6}>
        <Typography variant="h3">更改{title}</Typography>
      </Box>
      <Box bgcolor="background.gray" borderRadius="15px" height="2.4rem" lineHeight="2.4rem" pl={2} mb=".6rem">
        <InputBase
          fullWidth
          placeholder={`新${title}`}
          value={value}
          onChange={(e) => handleChange(e)}
        />
      </Box>
      <Box mb=".6rem" textAlign="left" color="error.main">
        <Typography>{value ? children : ''}</Typography>
      </Box>
      <Box style={{ background: `${value ? '#fde604' : '#ddd'}` }} clone>
        <Button fullWidth onClick={updateInfo} disabled={!value}>
          <Typography color={`${value ? 'inherit' : 'textSecondary'}`}>更改</Typography>
        </Button>
      </Box>
    </Modal>
  )
}

export default EditPopup
