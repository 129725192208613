import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { navigate } from '@reach/router'
import Container from '@layouts/Container'
import AvatarPanel from '@components/ProfileEdit/Avatar'
import ProfileInfo from '@components/ProfileEdit/BaseInfo'
import { getToken } from '@utils/auth'
import { getUserSummary } from '@services/api/user'

const ProfilePanel = () => {
  const isLogin = !!getToken()
  if (!isLogin) navigate("/account")
  const [isParse, setIsParse] = useState(false)
  const email = useStoreState((state) => state.user.email)
  const updateProfile = useStoreActions((actions) => actions.user.updateProfile)

  useEffect(() => {
    if (email) {
      setIsParse(true)
    } else {
      getUserSummary().then((res) => {
        updateProfile(res.data.profile)
        setIsParse(true)
      })
    }
  }, [])

  if (!isParse) {
    return null
  }

  return (
    <>
      <AvatarPanel />
      <Container>
        <ProfileInfo />
      </Container>
    </>
  )
}

export default ProfilePanel
