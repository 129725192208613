/* eslint-disable camelcase */
/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import { authenticate } from '@services/api/auth'
import { getQueryString, redirectToSSOURL, delParam } from '@utils/url'
import { getToken, setToken, setRefreshToken } from '@utils/auth'
import { navigate } from '@reach/router'

const Auth = ({ mode = '', children }) => {
  const [isLogin, setIsLogin] = useState(false)
  const [needlessLogin, setNeedLessLogin] = useState(false)
  const token = getToken()
  const verifyCode = getQueryString('code')
  const socialToken = getQueryString('social_access_token')

  const authentication = () => {
    if (typeof window !== `undefined`) {
      navigate(window.location.pathname + delParam(['code', 'social_access_token']), { replace: true })
    }
    const res = authenticate(socialToken
      ? {
        verify_code: verifyCode,
        social_token: socialToken,
      } : {
        verify_code: verifyCode,
      })
    res.then((response) => {
      const { access_token = '', refresh_token = '' } = response.data
      setToken(access_token)
      setRefreshToken(refresh_token)
      setIsLogin(true)
    }).catch(() => {
      // 400 verify code not exists or expired
      redirectToSSOURL()
    })
  }

  useEffect(() => {
    if (mode === 'needlessAuth') {
      if (verifyCode) {
        authentication()
      } else {
        setNeedLessLogin(true)
      }
    } else if (!token) {
      if (verifyCode) {
        authentication()
      } else {
        redirectToSSOURL() // not login
      }
    }
  }, [])

  if (token || isLogin || needlessLogin) {
    return <>{children}</>
  }
  return <></>
}

export default Auth
