/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import Message from '@components/Message'
import Modal from '@lib/ui/core/Modal'
import Box from '@lib/ui/core/Box'
import Link from '@lib/ui/core/Link'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Typography from '@lib/ui/core/Typography'
import { ReactComponent as DragImgIcon } from '@assets/icons/profile-drag.svg'
import AvatarPreview from './AvatarPreview'

const Line = styled.div`
  &:before, &:after {
    background: #e9e9e9;
    content: "";
    height: 2px;
    position: absolute;
    top: 50%;
    width: 40%;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
`

const UploadAvatarModal = ({ open, onClose }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [base64Img, setBase64Img] = useState('')
  const [errOpen, setErrOpen] = useState(false)
  const [errTip, setErrTip] = useState('')
  const MAX_FILE_SZIE = 5242880 // 5M

  const handleImage = (files) => {
    if (!files) return
    const type = ['png', 'jpg', 'jpeg']
    const filename = files.name.split('.')
    const ext = filename[filename.length - 1]
    if (!type.includes(ext)) {
      setErrOpen(true)
      setErrTip(`上傳圖片類型不支持.${ext}格式`);
      return
    }
    if (files.size > MAX_FILE_SZIE) {
      setErrOpen(true)
      setErrTip('上傳圖片大小不能超過5M');
      return
    }
    let fileReader = new FileReader() // eslint-disable-line
    fileReader.readAsDataURL(files)
    fileReader.onloadend = (e) => {
      onClose()
      setBase64Img(e.target.result)
      setPreviewOpen(true)
    }
  }

  const onDropAccepted = ((acceptedFiles) => {
    handleImage(acceptedFiles[0])
  })
  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    accept: 'image/*',
    onDropAccepted,
  })

  const handlePreviewClose = () => {
    setPreviewOpen(false)
  }

  const handleUploadClose = () => {
    setBase64Img('')
    onClose()
  }

  const handleErrClose = () => {
    setErrOpen(false)
  }

  const handleUploadClick = ({ target }) => {
    handleImage(target.files[0])
  }

  return (
    <>
      <Modal open={open} onClose={handleUploadClose}>
        <Box bgcolor="background.gray" borderRadius="15px" mt="1.2rem" py={3.8} px=".7rem">
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Box>
              <SvgIcon component={DragImgIcon} fontSize="5rem" />
            </Box>
            <Typography color="textSecondary" style={{ fontSize: '.75rem' }}>拉拽相片於此處</Typography>
          </Box>
          <Box position="relative" mt={2}>
            <Line><Typography color="textSecondary" style={{ fontSize: '.75rem' }}>或</Typography></Line>
          </Box>
          <Box mt={2}>
            <input id="btn-upload" type="file" style={{ display: 'none' }} accept="image/*" onChange={handleUploadClick} />
            <Link component="button" underline="always">
              <label htmlFor="btn-upload">按此上載相片</label>
            </Link>
          </Box>
        </Box>
      </Modal>
      <AvatarPreview open={previewOpen} onClose={handlePreviewClose} base64Img={base64Img} />
      <Message open={errOpen} severity="error" message={errTip} onClose={handleErrClose} />
    </>
  )
}

export default UploadAvatarModal
