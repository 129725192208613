/* eslint-env browser */
import React from 'react'
import { useStoreActions } from 'easy-peasy'
import { navigate } from '@reach/router'
import { updateUserProfile } from '@services/api/user'
import Modal from '@lib/ui/core/Modal'
import Button from '@lib/ui/core/Button'
import Box from '@lib/ui/core/Box'

const AvatarPreview = ({ open, onClose, base64Img }) => {
  const updateAvatar = useStoreActions((actions) => actions.user.updateAvatar)

  const handleUpdateAvatar = () => {
    updateUserProfile({
      avatar: base64Img.replace(/^data:image\/\w+;base64,/, ""),
    }).then((res) => {
      onClose()
      updateAvatar(res.data.avatar)
    })
  }

  const reset = () => {
    navigate('/account')
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box width="9.1rem" height="9.1rem" display="inline-block" borderRadius="50%" textAlign="center" clone>
        <img src={base64Img} alt="" />
      </Box>
      <Box display="flex" justifyContent="space-between" mt="1rem">
        <Box width="9.1rem" clone>
          <Button onClick={handleUpdateAvatar}>套用</Button>
        </Box>
        <Box width="9.1rem" style={{ backgroundColor: '#f5f5f5' }} clone>
          <Button onClick={reset}>重設</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AvatarPreview
