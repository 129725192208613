import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { ReactComponent as CreateIcon } from '@assets/icons/pencil.svg'
import LockIcon from '@material-ui/icons/Lock'
import Box from '@lib/ui/core/Box'
import ButtonBase from '@lib/ui/core/ButtonBase'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Typography from '@lib/ui/core/Typography'
import { updateUserProfile } from '@services/api/user'
import EditPopup from './EditPopup'

const ProfileInfo = () => {
  const email = useStoreState((state) => state.user.email)
  const nickname = useStoreState((state) => state.user.profile.nickname)
  const updateName = useStoreActions((actions) => actions.user.updateName)
  const [nameOpen, setNameOpen] = useState(false)
  const [isNameErr, setIsNameErr] = useState('')

  const handleNameOpen = () => {
    setNameOpen(true)
  }

  const handleNameClose = () => {
    setIsNameErr('')
    setNameOpen(false)
  }

  const handleNameSubmit = (value) => {
    if (value.length <= 20) {
      setIsNameErr('')
      updateUserProfile({
        nickname: value,
      }).then(() => {
        handleNameClose()
        updateName(value)
      })
    } else {
      setIsNameErr('名稱不能超過20個字符')
    }
  }

  return (
    <>
      <Box mt="1.2rem">
        <Typography style={{ fontSize: '.75rem', fontWeight: 100 }} color="textSecondary">名稱</Typography>
        <Box borderBottom="2px solid #f5f5f5" pt=".4rem">
          <ButtonBase disableRipple style={{ width: '100%', paddingBottom: '.6rem' }} onClick={handleNameOpen}>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography>{nickname || '著數子'}</Typography>
              <SvgIcon component={CreateIcon} fontSize="1.2rem" />
            </Box>
          </ButtonBase>
        </Box>
      </Box>
      {/* <Box mt="1.2rem">
        <Typography style={{ fontSize: '.75rem', fontWeight: 100 }} color="textSecondary">密碼</Typography>
        <Box borderBottom="2px solid #f5f5f5" pt=".4rem">
          <ButtonBase disableRipple style={{ width: '100%', paddingBottom: '.6rem' }}>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography>••••••</Typography>
              <SvgIcon component={CreateIcon} fontSize="1.2rem" />
            </Box>
          </ButtonBase>
        </Box>
      </Box> */}
      <Box mt="1.2rem">
        <Typography style={{ fontSize: '.75rem', fontWeight: 100 }} color="textSecondary">電郵</Typography>
        <Box borderBottom="2px solid #f5f5f5" pt=".4rem">
          <ButtonBase disableRipple style={{ width: '100%', paddingBottom: '.6rem' }}>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography>{email}</Typography>
              <SvgIcon component={LockIcon} fontSize="1.2rem" color="text.secondary" />
            </Box>
          </ButtonBase>
        </Box>
      </Box>
      <EditPopup open={nameOpen} title="名稱" onSubmit={handleNameSubmit} onClose={handleNameClose}>
        {isNameErr}
      </EditPopup>
    </>
  )
}

export default ProfileInfo
